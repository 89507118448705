<template>
  <div class="about">
    <Banner :banner="banner" />
    <section id="brand" class="first_section">
      <div class="row col-9 mx-auto">
        <Info :data="MCN" />
        <div
          class="col-lg-6 col-12 wow animate__animated animate__fadeInUp"
          data-wow-duration="1s"
        >
          <img :src="require('../assets/marketing/01.jpg')" alt="" />
        </div>
      </div>
    </section>
    <section id="product">
      <div class="row col-9 mx-auto">
        <Info :data="multimedia" />
        <div
          class="col-lg-6 col-12 wow animate__animated animate__fadeInUp"
          data-wow-duration="1s"
        >
          <img :src="require('../assets/marketing/02.jpg')" alt="" />
        </div>
      </div>
    </section>
    <section id="stream">
      <div class="row col-9 mx-auto">
        <Info :data="Planning" />
        <div
          class="col-lg-6 col-12 wow animate__animated animate__fadeInUp"
          data-wow-duration="1s"
        >
          <img :src="require('../assets/marketing/03.jpg')" alt="" />
        </div>
      </div>
    </section>
    <section id="chain">
      <div class="row col-9 mx-auto">
        <Info :data="rental" />
        <div
          class="col-lg-6 col-12 wow animate__animated animate__fadeInUp"
          data-wow-duration="1s"
        >
          <img :src="require('../assets/marketing/04.jpg')" alt="" />
        </div>
      </div>
    </section>
  </div>
</template>
<script>
// @ is an alias to /src
import Banner from "@/components/Banner";
import Info from "@/components/Info";

export default {
  components: {
    Banner,
    Info,
  },
  data() {
    return {
      banner: {
        url: require("../assets/hce/banner.jpg"),
        text: {
          title: "整合行銷",
          content:
            "翰成數位擁有豐富國際貿易、品牌運營、直播專場、短影音帶貨、產品代操作、供應鏈整合經驗、我們竭誠歡迎優良廠商合作共創商機。",
        },
      },
      MCN: {
        text: {
          title: "品牌運營",
          content: `以品牌為核心，強化品牌形象與特色宣傳，協助品牌行銷資源最佳分配，結合翰成數位營銷數據應用，建立品牌數據資料庫，為品牌精準分析並建立全方位品牌通路銷售策略。`,
        },
      },
      multimedia: {
        text: {
          title: "產品代操",
          content: `翰成數位擁有電商經驗資深團隊，包含專屬品牌經理、通路業務、視覺設計與後勤營運管理支援，完整團隊專業分工，結合市場洞察、業務目標打造品牌產品數位化介面，跨媒體數位整合行銷，透過數據分析與策略優化，幫助您把流量化為營收。`,
        },
      },
      Planning: {
        text: {
          title: "直播專場",
          content: `運用直播最即時性的互動模式，透過主播向消費者傳達內容同時進行互動，提高觀眾的參與度，並藉機展現商品特色，強化品牌與用戶的連結，打造直播變現模式，不僅比競爭者更有優勢，長久經營下，更能在全民直播時代嶄露頭角！`,
        },
      },
      rental: {
        text: {
          title: "短影音創作",
          content: `以短影音模式快速抓住消費者注意力，快速引起購買慾望、促成消費。並運用短影音最佳化流程四要點：決定定位、3秒演算法、8 秒完播率、引流，並結合翰成數位專業團隊，運用影音內容大數據優化供應鏈，整合線上線下影音廣告，驅動需求與服務。`,
        },
      },
    };
  },
};
</script>
<style lang="scss"></style>
